export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS="UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL="UPLOAD_FILE_FAIL";

// ADD_RESTAURANT_DATA
export const ADD_RESTAURANT_START = "ADD_RESTAURANT_START";
export const ADD_RESTAURANT_SUCCESS ="ADD_RESTAURANT_SUCCESS";
export const ADD_RESTAURANT_FAILED = "ADD_RESTAURANT_FAILED";

// LIST_RESTAURANT_DATA
export const LIST_RESTAURANT_START = "LIST_RESTAURANT_START";
export const LIST_RESTAURANT_SUCCESS ="LIST_RESTAURANT_SUCCESS";
export const LIST_RESTAURANT_FAILED = "LIST_RESTAURANT_FAILED";