import Decode from 'jwt-decode';
import S3FileUpload from "react-s3";
import { S3_BUCKET, REGION, ACCESS_KEY, SECRET_ACCES_KEY, DIRECTORY_NAME } from "../../assets/utils/config";

//IMPORT URL
import { RESTAURANT_LIST_URL, ADD_RESTAURANT_URL} from "../../urls/home/home";

// IMPORT TYPES
import {
    UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL,ADD_RESTAURANT_START, ADD_RESTAURANT_SUCCESS, ADD_RESTAURANT_FAILED,
    LIST_RESTAURANT_SUCCESS, LIST_RESTAURANT_FAILED,LIST_RESTAURANT_START
} from "../../types/home/home";

const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCES_KEY,
    dirName: DIRECTORY_NAME
}

export const uploadFileStart = () => {
    return {
        type: UPLOAD_FILE_START
    }
}

export const uploadFileSuccess = (filename) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        filename: filename
    }
}

export const uploadFileFail = () => {
    return {
        type: UPLOAD_FILE_FAIL
    }
}

export const uploadFileService = (data) => {

    console.log("dataaa====>>>", data)
    return dispatch => {
        dispatch(uploadFileStart())
        S3FileUpload.uploadFile(data, config)
            .then(response => {
                console.log(response)
                if (response.result.ok) {
                    dispatch(uploadFileSuccess(response.location))
                }
                else {
                    dispatch(uploadFileFail())
                }
            })
            .catch((err) => {
                console.log("errrrrr ====>>>>", err)
            })
    }
}

export const addRestaurantStart = () => {
    return {
        type: ADD_RESTAURANT_START
    }
}

export const addRestaurantSuccess = () => {
    return {
        type: ADD_RESTAURANT_SUCCESS
    }
}

export const addRestaurantFail = (error) => {
    return {
        type: ADD_RESTAURANT_FAILED,
        error: error
    }
}

export const addRestaurantService = (email, password, nom, proprietaire, addresse, telephone,token) => {
    return dispatch => {
        dispatch(addRestaurantStart())
        fetch(ADD_RESTAURANT_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            },
            body: JSON.stringify({
                "nom": nom,
                "telephone": telephone,
                "email": email,
                "password": password,
                "addresse": addresse,
                "proprietaire": proprietaire
            })
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(addRestaurantSuccess());
                }
                else {
                    dispatch(addRestaurantFail("error"))
                }
            })
            .catch(error => {
                dispatch(addRestaurantFail(error.message))
            })
    }
}

export const listRestaurantStart = () => {
    return {
        type: LIST_RESTAURANT_START
    }
}

export const listRestaurantSuccess = (restaurantlist) => {
    return {
        type: LIST_RESTAURANT_SUCCESS,
        restaurantlist:restaurantlist
    }
}

export const listRestaurantFail = (error) => {
    return {
        type: LIST_RESTAURANT_FAILED,
        error: error
    }
}

export const listRestaurantService = (token) => {
    return dispatch => {
        dispatch(listRestaurantStart())
        fetch(RESTAURANT_LIST_URL, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
            .then(response => response.json())
            .then(response => {
                console.log(response)
                if (response.responseStatus === 200) {
                    dispatch(listRestaurantSuccess(response.data.response));
                }
                else {
                    dispatch(listRestaurantFail("error"))
                }
            })
            .catch(error => {
                dispatch(listRestaurantFail(error.message))
            })
    }
}

