import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../actions/auth/auth';
import {  } from '../../actions/home/home';
import Logo from "../../assets/images/logo.jpg";

const Header = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const homedata = useSelector(state => state.HomeReducer.homedata);

  useEffect(() => {
    //dispatch(getHomeDataService(token))
  }, []);

  return (
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur"
      data-scroll="true">
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <h6 class="font-weight-bolder mb-0">Bienvenue Admin !</h6>
        </nav>
        <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
          <div class="ms-md-auto pe-md-3 d-flex align-items-center">
            <div class="input-group input-group-outline">
              <label class="form-label">Tapez ici...</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <ul class="navbar-nav  justify-content-end">
            <li class="nav-item d-flex align-items-center">
              <a class="btn btn-outline-primary btn-sm mb-0 me-3">Parametre</a>
            </li>
            <li class="nav-item d-flex align-items-center">
              <a onClick={() => dispatch(logOut())} class="btn btn-outline-primary btn-sm mb-0 me-3">Deconnexion</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default Header;