import * as actionTypes from '../../types/home/home'

const initialState = {
    loadingupload: false,
    error: false,
    filename: "",
    loading_add_restaurant: false,
    addrestaurantstatus: false,

    loading_list_restaurant: false,
    restaurantlist: []
}

const ReducerHome = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_FILE_START:
            return {
                ...state,
                loadingupload: true,
                error: null
            }
        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loadingupload: false,
                error: false,
                filename: action.filename
            }
        case actionTypes.UPLOAD_FILE_FAIL:
            return {
                ...state,
                error: action.error,
                loadingupload: false
            }
        case actionTypes.ADD_RESTAURANT_START:
            return {
                ...state,
                loading_add_restaurant: true,
                error: false
            }
        case actionTypes.ADD_RESTAURANT_SUCCESS:
            return {
                ...state,
                loading_add_restaurant: false,
                error: false,
                addrestaurantstatus: true
            }
        case actionTypes.ADD_RESTAURANT_FAILED:
            return {
                ...state,
                error: action.error,
                loading_add_restaurant: false,
            }
        case actionTypes.LIST_RESTAURANT_START:
            return {
                ...state,
                loading_list_restaurant: true,
                addrestaurantstatus: false,
                error: false
            }
        case actionTypes.LIST_RESTAURANT_SUCCESS:
            return {
                ...state,
                loading_list_restaurant: false,
                error: false,
                restaurantlist: action.restaurantlist
            }
        case actionTypes.LIST_RESTAURANT_FAILED:
            return {
                ...state,
                error: action.error,
                loading_list_restaurant: false,
            }


        default:
            return state
    }
}

export default ReducerHome