
import React, { useEffect } from 'react'

//IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginCheckState } from './actions/auth/auth';


import Login from "./pages/authentication/login";
import Panel from "./pages/home/panel";
import RestaurantList from './pages/restaurants/list';
import AddRestaurant from './pages/restaurants/add';


const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.AuthReducer.token);
  const role = useSelector(state => state.AuthReducer.role);

  useEffect(() => {
    dispatch(loginCheckState());
  }, []);

  let route = (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect to='/login' />
    </Switch>
  )

  if (token && (role === 'admin')) {
    route = (
      <Switch>
         <Route path="/add-restaurant"  component={AddRestaurant} />
         <Route path="/list-restaurant"  component={RestaurantList} />
        <Route path="/" exact component={Panel} />
        <Redirect to='/' />
      </Switch>
    )
  }

  return (
      <>
       {route}
      </>   
  
  );
}

export default withRouter(App);
